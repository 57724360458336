<template>
  <div class="appointmentList-view">
    <p>用户信息</p>
    <commonTable :tableData="tableData" :loading="loading" :paginationVisible="false">
      <template v-slot:table>
        <el-table-column align="center" type="index" width="50" label="序号"/>
        <el-table-column prop="avatarUrl" align="center" label="头像">
          <template slot-scope="scope"
            ><img :src="scope.row.avatarUrl" class="w50 h50" />
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" label="名称" />
        <el-table-column prop="phone" align="center" label="联系方式" />
        <el-table-column prop="successNum" align="center" label="总成交单数"/>
        <el-table-column prop="orderAmount" align="center" label="总订单金额(元)" />
        <el-table-column prop="sumBalance" align="center" label="总佣金(元)"/>
        <el-table-column prop="balance" align="center" label="可提现佣金(元)" />
        <el-table-column prop="withdrawBalance" align="center" label="提现中的佣金(元)"/>
        <el-table-column prop="withdrawingBalance" align="center" label="已提现佣金(元)"/>
      </template>
    </commonTable>
    <p class="">佣金详情编号</p>
    <commonTable :tableData="tableDataList"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column prop="ordersNo" align="center" label="订单编号" />
        <el-table-column prop="orderAmount" align="center" label="订单实付金额" />
        <el-table-column prop="commissionRate" align="center" label="佣金比例" />
        <el-table-column prop="commissionAmount" align="center" label="可得佣金" />
        <el-table-column prop="completeTime" align="center" label="订单完成时间" />
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  queryCommissionRecordDetail
} from "@/api/financing";
import { getDataTimeSec } from "@/utils";
export default {
  name: "appointmentList",
  components: {
    commonTable,
  },
  data() {
    return {
      value: "",
      tableData: [],
      tableDataList:[],
      optionList:'', //全部分类
      loading:'',
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      detallId:'' , //详情id
    };
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  created(){
    this.detallId = this.$route.query.id;
    if (this.detallId) {
      this.getTableData();
    }
  },
  mounted(){

  },
  methods:{
    //请求表格数据
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        distributorAppId: this.detallId,
      };
      queryCommissionRecordDetail(data)
      .then((res) => {
        let arr = []
        this.loading = false;
        this.tableData = [res.data] || [];
        //-console.log(this.tableData)
        this.total = res.data.total;
        this.tableDataList = res.data.responseList || [];
        if(this.tableDataList){
          this.tableDataList.forEach(item =>{
            item.commissionRate = item.commissionRate+"%";
          });
        }
      })
      .catch((err) => {
        this.loading = false;
        this.tableData = [];
      });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.getTableData();
    },
  }
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>